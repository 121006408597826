<template>
  <div>
    <div class="d-flex flex-row align-center" style="gap: 12px">
      <v-badge v-if="filtersToShow.includes('all') || filtersToShow.includes('campaignDates')" :value="filters.dates.length > 0" overlap bordered>
        <date-select v-model="filters.dates" label="Início da campanha" type="range" @change="emitChange" />
      </v-badge>

      <select-options-filter
        v-if="filtersToShow.includes('all') || filtersToShow.includes('status')"
        v-model="filters.status"
        label="Status"
        :options="statusOptions"
        @change="emitChange"
      />

      <v-badge v-if="filtersToShow.includes('all') || filtersToShow.includes('smsDates')" :value="filters.smsDates.length > 0" overlap bordered>
        <date-select v-model="filters.smsDates" label="Data do SMS" type="range" @change="emitChange" />
      </v-badge>

      <v-badge v-if="filtersToShow.includes('all') || filtersToShow.includes('proposalDeadline')" :value="filters.proposalDeadline.length > 0" overlap bordered>
        <date-select v-model="filters.proposalDeadline" label="Prazo de adesão" type="range" @change="emitChange" />
      </v-badge>

      <select-options-filter
        v-if="filtersToShow.includes('all') || filtersToShow.includes('offerTypes')"
        v-model="filters.offerTypes"
        label="Tipo de oferta"
        :options="offerTypeOptions"
        @change="emitChange"
      />

      <select-options-filter
        v-if="filtersToShow.includes('all') || filtersToShow.includes('industry')"
        v-model="filters.industries"
        label="Indústria"
        :options="industryNamesAndIds"
        item-text="name"
        item-value="_id"
        return-object
        @change="emitChange"
      />

      <mf-button text color="primary" label="Redefinir" icon="filter_alt_off" @click="resetFilter"></mf-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useIndustryStore } from '@/stores'

export default {
  name: 'HeaderFilter',
  components: {
    DateSelect: () => import('./DateSelect.vue'),
    SelectOptionsFilter: () => import('./SelectOptionsFilter.vue')
  },
  props: {
    value: {
      type: [Object, String]
    },
    filtersToShow: {
      type: Array,
      required: true,
      default: () => ['all']
    },
    type: {
      type: String,
      default: 'none',
      validator: value => ['mercapromo', 'mercatrade', 'none'].includes(value)
    }
  },
  data: () => ({
    filters: {
      dates: [],
      status: [],
      offerTypes: [],
      industries: [],
      smsDates: [],
      proposalDeadline: []
    },
    oldFilters: {
      dates: [],
      status: [],
      offerTypes: [],
      industries: [],
      smsDates: [],
      proposalDeadline: []
    }
  }),
  computed: {
    ...mapState(useIndustryStore, ['industryNamesAndIds']),

    offerTypeOptions() {
      let offerTypeOptions = [
        {
          text: 'Desconto Absoluto',
          value: 'general'
        },
        {
          text: 'Leve & Pague',
          value: 'takepay'
        },
        {
          text: 'Cashback',
          value: 'nextbuycashback'
        },
        {
          text: 'Voucher Digital',
          value: 'dynamic_rule'
        },
        {
          text: 'Cross Sell',
          value: 'cross_sell'
        },
        {
          text: 'Desconto Percentual',
          value: 'percentage'
        }
      ]

      if (this.type === 'mercatrade') {
        offerTypeOptions = offerTypeOptions.splice(3, 3)
      }

      return offerTypeOptions
    },

    statusOptions() {
      return [
        {
          text: 'Processado',
          value: 'processed'
        },
        {
          text: 'Em processamento',
          value: 'processing'
        },
        {
          text: 'Com erro',
          value: 'error'
        },
        {
          text: 'Removido',
          value: 'deleted'
        },
        {
          text: 'A ser removido',
          value: 'to_be_deleted'
        }
      ]
    }
  },
  mounted() {
    if (this.value) this.filters = this.value
  },
  methods: {
    emitChange() {
      if (JSON.stringify(this.oldFilters) === JSON.stringify(this.filters)) return
      this.$emit('input', this.filters)
      this.oldFilters = { ...this.filters }
    },
    resetFilter() {
      this.filters.dates = []
      this.filters.status = []
      this.filters.offerTypes = []
      this.filters.industries = []
      this.filters.smsDates = []
      this.filters.proposalDeadline = []
      this.emitChange()
    }
  }
}
</script>
